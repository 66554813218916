export const LOGIN = 1;
export const REQUEST_MOVEMENT = 7;
export const REQUEST_CHAT = 9;
export const REQUEST_LOOK_AT = 12;
export const REQUEST_WAVE = 13;
export const REQUEST_ROOM_DATA = 15;
export const REQUEST_ITEM_INTERACT = 18;
export const REQUEST_ITEM_MOVE = 19;
export const REQUEST_ITEM_PICK_UP = 20;
export const REQUEST_CHANGE_LOOKS = 21;
export const REQUEST_CHANGE_MOTTO = 22;
export const REQUEST_INVENTORY_ITEMS = 23;
export const REQUEST_ITEM_PLACE = 24;
export const REQUEST_CATALOGUE_INDEX = 25;
export const REQUEST_CATALOGUE_PAGE = 26;
export const REQUEST_CATALOGUE_PURCHASE = 27;
export const REQUEST_NAVIGATOR_POPULAR_ROOMS = 28;
export const REQUEST_NAVIGATOR_OWN_ROOMS = 29;
export const REQUEST_NAVIGATOR_SEARCH_ROOMS = 30;
export const REQUEST_NAVIGATOR_MAKE_FAVOURITE = 31;
export const REQUEST_NAVIGATOR_REMOVE_FAVOURITE = 32;
export const REQUEST_NAVIGATOR_LEAVE_ROOM = 33;
export const REQUEST_NAVIGATOR_GO_TO_ROOM = 34;
export const REQUEST_HEIGHT_MAP = 2;
export const REQUEST_NAVIGATOR_CREATE_ROOM = 35;
export const REQUEST_MESSENGER_ACCEPT_FRIEND = 36;
export const REQUEST_MESSENGER_DENY_FRIEND = 37;
export const REQUEST_MESSENGER_FOLLOW_FRIEND = 38;
export const REQUEST_MESSENGER_SEARCH_FRIEND = 39;
export const REQUEST_MESSENGER_SEND_MESSAGE = 40;
export const REQUEST_MESSENGER_REMOVE_FRIEND = 41;
export const REQUEST_MESSENGER_ADD_FRIEND = 42;
export const REQUEST_MESSENGER_LOAD_FRIENDS = 42;