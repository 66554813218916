export const ROOM_SELECTED_TILE = "assets/selected_tile.png";
export const ROOM_TILE_HEIGHT = 16;
export const ROOM_TILE_WIDTH = 32;
export const FLOOR_ITEM_PLACEHOLDER = "assets/furni_placeholder.png";
export const FLOOR_ITEM_PLACEHOLDER_OFFSET_X = -34;
export const FLOOR_ITEM_PLACEHOLDER_OFFSET_Y = -49;
export const WALL_ITEM_PLACEHOLDER = "assets/wall_placeholder.png";
export const WALL_ITEM_PLACEHOLDER_OFFSET_X = -18;
export const WALL_ITEM_PLACEHOLDER_OFFSET_Y = -25;
export const ROOM_WALL_R_OFFSET_X = +64;
export const ROOM_WALL_R_OFFSET_Y = -139;
export const ROOM_WALL_L_OFFSET_X = -8;
export const ROOM_WALL_L_OFFSET_Y = -123;
export const ROOM_TILE_SHADOW = "assets/shadow_tile.png";
