export const LOGIN_OK = 3;
export const ROOM_DATA_HEIGHTMAP = 4;
export const PLAYERS_DATA = 6;
export const PLAYER_STATUS = 8;
export const CHAT = 10;
export const PLAYER_REMOVE = 11;
export const PLAYER_WAVE = 14;
export const ROOM_ITEM_DATA = 16;
export const ITEM_REMOVE = 17;
export const ITEM_STATE = 19;
export const WALL_ITEM_DATA = 20;
export const INVENTORY_ITEMS = 21;
export const INVENTORY_ITEM_REMOVE = 22;
export const CATALOGUE_INDEX = 23;
export const CATALOGUE_PAGE = 24;
export const CATALOGUE_PURCHASE_ERROR = 25;
export const CATALOGUE_PURCHASE_INFO = 26;
export const CREDITS_BALANCE = 27;
export const ROOM_DATA_MODEL_INFO = 28;
export const ROOM_DATA = 29;
export const NAVIGATOR_ROOM_LIST = 30;
export const NAVIGATOR_LEAVE_ROOM = 31;
export const MESSENGER_FRIENDS = 32;
export const MESSENGER_SEARCH_RESULT = 33;
export const MESSENGER_MESSAGE = 34;
export const MESSENGER_REQUESTS = 35;
export const MESSENGER_UPDATE_FRIEND = 36;
